import React from 'react'
import WishWashImage from './wishwash.jpg'
import WishWashAppImage from './wishwash_app.png'
import androidImage from './android.png'
import iosImage from './ios.svg'
import { LanguageSelectorWrapper } from '../../components/LanguageSelector'
import {
    Button,
    Grid,
    Link,
    Typography
} from '@mui/material'
import {
    Box,
    Container
} from '@mui/system'

export default function WishWash() {
    return (
        <Box
            style={{
                height: 'calc(100vh)',
                display: 'flex',
                flexDirection: 'column',
            }}
        >
            <Container maxWidth="lg">
                <Grid
                    p={2}
                    container
                    justifyContent="space-between"
                    alignItems="center"
                >
                    <Grid item>
                        <Typography align="center" display="block" >
                            <Link href="https://www.facebook.com/wishwasheg" target="_blank">
                                <img src={WishWashImage} width={75} alt="wishwash logo" />
                            </Link>
                        </Typography>
                    </Grid>
                    <Grid item>
                        <LanguageSelectorWrapper />
                    </Grid>
                </Grid>
            </Container>
            <Grid
                py={2}
                container
                maxWidth={"md"}
                spacing={2}
                justifyContent="center"
                sx={{ marginInline: 'auto' }}
                alignItems="center"
            >
                <Grid item xs={12} md={6}>
                    <Typography
                        variant="h3"
                        fontWeight={500}
                        align="center"
                        display="block"
                        style={{ fontSize: '30px' }}
                        gutterBottom
                    >
                        A New Area of <br /> Eco Friendly  Laundry
                    </Typography>
                    <Grid container spacing={1} pt={2} justifyContent="center" alignItems="center">
                        <Grid item >
                            <Button
                                style={{
                                    background: `url(${androidImage}) no-repeat`,
                                    backgroundSize: 'contain',
                                    display: 'inline-block',
                                    overflow: 'hidden',
                                    textDecoration: 'none',
                                    height: '75px',
                                    width: '130px',
                                }}
                                component="a"
                                href="https://play.google.com/store/apps/details?id=com.eg.wishwash.wishwash"
                                target="_blank"
                            />
                        </Grid>
                        <Grid item>
                            <Button
                                style={{
                                    background: `url(${iosImage}) no-repeat`,
                                    backgroundSize: 'contain',
                                    display: 'inline-block',
                                    overflow: 'hidden',
                                    textDecoration: 'none',
                                    height: '75px',
                                    width: '130px',
                                }}
                                component="a"
                                href="https://apps.apple.com/eg/app/wishwash-laundry/id1481519517"
                                target="_blank"
                            />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12} md={6}>
                    <Typography align='center'>
                        <img src={WishWashAppImage} width={250} alt="wishwash app" />
                    </Typography>
                </Grid>
            </Grid>
        </Box>
    )
}