import React from 'react'
import ReactDOM from 'react-dom/client'
import './index.css'
import App from './modules/App'
import {
    BrowserRouter
} from "react-router-dom"
import { ErrorBoundary, ScrollToTop } from './modules/components'
import { QueryProvider, ThemeProvider, } from './modules/providers'
import './modules/providers/LanguageProvider'
import { SnackbarProvider } from 'notistack'
import './modules/config/firebase'

const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
    <ErrorBoundary>
        <BrowserRouter>
            <QueryProvider>
                <React.Suspense fallback="">
                    <ThemeProvider>
                        <SnackbarProvider
                            dense
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'right',
                            }}
                        >
                            <ScrollToTop />
                            <App />
                        </SnackbarProvider>
                    </ThemeProvider>
                </React.Suspense>
            </QueryProvider>
        </BrowserRouter>
    </ErrorBoundary>
)
