
import React from "react"
import {
    Routes,
    Route,
} from "react-router-dom"
import {
    WishWashView,
    WishWashAuthView,
    NotFoundView
} from "./views"
function App() {
    return (
        <Routes>
            <Route path="/">
                <Route index element={<WishWashView />} />
                <Route path=":tenant_id/:token" element={<WishWashAuthView />} />
            </Route>
            <Route path="*" element={<NotFoundView />} />
        </Routes>
    )
}

export default App
