import React from 'react'
import { useParams, useNavigate } from "react-router-dom"
import { useGetTokenDetails } from '../../api/wishwash'
import WishWashImage from './wishwash.jpg'
import MuiPhoneNumber from 'material-ui-phone-number'
import { useFormik } from 'formik'
import { useTranslation } from 'react-i18next'
import { LanguageSelectorWrapper } from '../../components/LanguageSelector'
import { useSnackbar } from 'notistack'
import {
    Button,
    CircularProgress,
    Grid,
    Link,
    Paper,
    TextField,
    Typography
} from '@mui/material'
import {
    Box,
    Container
} from '@mui/system'
import {
    getAuth,
    RecaptchaVerifier,
    signInWithPhoneNumber,
    signOut
} from 'firebase/auth'
import { useRegister } from '../../api/wishwashAuth'


export default function WishWashAuth() {
    const { token, tenant_id } = useParams()
    const tokenDetailsQuery = useGetTokenDetails(token, tenant_id)
    const { t } = useTranslation()
    return (
        <Container
            maxWidth="sm"
            component={Box}
            mt={5}
            style={{
                height: 'calc(100vh - 40px)',
                display: 'flex',
                flexDirection: 'column'
            }}
        >
            <Typography align="center" display="block" >
                <Link href="https://www.facebook.com/wishwasheg" target="_blank">
                    <img src={WishWashImage} width={100} alt="wishwash logo" />
                </Link>
            </Typography>
            <Box p={2}>
                <Typography
                    variant="button"
                    align="center"
                    display="block"
                    style={{ fontSize: '30px' }}
                >
                    {t("wishwash.Join_Us")}
                </Typography>
            </Box>
            <Paper component={Box} p={5}>
                {
                    tokenDetailsQuery.isLoading
                        ? <Grid container justifyContent="center"><CircularProgress /></Grid>
                        : tokenDetailsQuery.isError
                            ? 'error, try again later or ask for new qr-code'
                            : <WishWashEnroll token={token} tenant_id={tenant_id} />
                }
            </Paper>
            <Grid
                py={2}
                container
                justifyContent="center"
                alignItems="center"
                sx={{ height: '100%' }}
            >
                <Grid item>
                    <LanguageSelectorWrapper />
                </Grid>
            </Grid>
        </Container>
    )
}

function WishWashForm({ onSubmit }) {
    const { t } = useTranslation()
    const formik = useFormik({
        initialValues: { phone: '' },
        onSubmit,
    })
    return <Grid component="form" onSubmit={formik.handleSubmit} container spacing={2}>
        <Grid item xs={12}>
            <MuiPhoneNumber
                countryCodeEditable={false}
                defaultCountry='eg'
                onlyCountries={['eg']}
                disableAreaCodes
                color="success"
                fullWidth
                label={t("wishwash.Phone")}
                name='phone'
                variant="outlined"
                value={formik.values.phone}
                onChange={(value) => formik.setFieldValue('phone', value)}
                onBlur={formik.handleBlur}
                error={formik.touched.phone && Boolean(formik.errors.phone)}
                helperText={formik.touched.phone && formik.errors.phone}
            />
        </Grid>
        <Grid item xs={12}>
            <Grid container justifyContent="flex-end" spacing={1}>
                <Grid item>
                    <Button
                        onClick={() => formik.resetForm()}
                        variant="outlined"
                        color="success"
                    >
                        {t("common.Reset")}
                    </Button>
                </Grid>
                <Grid item>
                    <Button
                        disabled={formik.isSubmitting}
                        variant="contained"
                        color="success"
                        type="submit"
                    >
                        {t("wishwash.Enroll")}
                    </Button>
                </Grid>
            </Grid>
        </Grid>
    </Grid>
}

function WishWashOTP({ onSubmit }) {
    const { t } = useTranslation()
    const formik = useFormik({
        initialValues: { otp: '' },
        onSubmit,
    })
    return <Grid component="form" onSubmit={formik.handleSubmit} container spacing={2}>
        <Grid item xs={12}>
            <TextField
                id="otp"
                name="otp"
                label={t("common.otp")}
                variant='outlined'
                fullWidth
                value={formik.values.otp || ''}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.otp && Boolean(formik.errors.otp)}
                helperText={formik.touched.otp && formik.errors.otp}
            />
        </Grid>
        <Grid item xs={12}>
            <Grid container justifyContent="flex-end" spacing={1}>
                <Grid item>
                    <Button
                        onClick={() => formik.resetForm()}
                        variant="outlined"
                        color="success"
                    >
                        {t("common.Reset")}
                    </Button>
                </Grid>
                <Grid item>
                    <Button
                        disabled={formik.isSubmitting}
                        variant="contained"
                        color="success"
                        type="submit"
                    >
                        {t("wishwash.Enroll")}
                    </Button>
                </Grid>
            </Grid>
        </Grid>
    </Grid>
}


function WishWashEnroll({
    token,
    tenant_id,
}) {
    // const { t } = useTranslation()
    const registerMutation = useRegister()
    const [confirmationResult, setConfirmationResult] = React.useState(null)
    const recaptchaVerifier = React.useMemo(() => new RecaptchaVerifier('recaptcha-container', { 'size': 'invisible', }, getAuth()), [])
    const { enqueueSnackbar } = useSnackbar()
    const navigate = useNavigate()
    const handlePhoneSubmit = (values, actions) => {
        signInWithPhoneNumber(getAuth(), values.phone, recaptchaVerifier)
            .then((confirmationResult) => setConfirmationResult(confirmationResult))
            .catch(() => enqueueSnackbar(`Invalid phone number`, { variant: 'error', }))
            .finally(() => actions.setSubmitting(false))
    }
    const handleOTPSubmit = (values, actions) => {
        confirmationResult.confirm(values.otp)
            .then((result) => {
                const user = result.user
                registerMutation
                    .mutateAsync({
                        token,
                        tenant_id,
                        user_id: user.uid
                    })
                    .then(() => {
                        enqueueSnackbar(`Registered Successfully`, { variant: 'success', })
                        navigate(`/`)
                    })
                    .catch(() => {
                        enqueueSnackbar(`unknown error occurred, try again later`, { variant: 'error', })
                    })
                    .finally(() => {
                        actions.setSubmitting(false)
                        signOut(getAuth())
                    })
            })
            .catch(() => {
                enqueueSnackbar(`Invalid OTP`, { variant: 'error', })
                actions.setSubmitting(false)
            })
    }

    return (
        <Grid container spacing={4}>
            <Grid item xs={12}>
                <Typography variant="h5"></Typography>
            </Grid>
            <Grid item xs={12}>
                {
                    !confirmationResult
                        ? <WishWashForm onSubmit={handlePhoneSubmit} />
                        : <WishWashOTP onSubmit={handleOTPSubmit} />
                }
            </Grid>
        </Grid>

    )
}